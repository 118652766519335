@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-ExtraLight.woff2") format("woff2");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Light.woff2") format("woff2");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Medium.woff2") format("woff2");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-SemiBold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-ExtraBold.woff2") format("woff2");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Variable.woff2") format("woff2");
	font-weight: 800;
	font-style: normal;
}
* {
	font-family: "Manrope";
}

html,
body {
	width: 100%;
	height: 100vh;
	padding: 0;
	margin: 0;

	.container {
		max-width: 1140px;
		// padding: 0;
	}
}

.modal-open {
	overflow: hidden;
}

.spacer {
	flex-grow: 1;
}

/**
 * Remove focus styles for non-keyboard focus.
 */
:focus:not(:focus-visible) {
	outline: none !important;
	box-shadow: none !important;
	border-color: gray !important;
}

input[type="radio"]:checked {
	background-color: #ed224d !important;
	border-color: #ed224d !important;
}

input[type="checkbox"] {
	border-radius: 0em !important;
}

input[type="checkbox"]:checked {
	background-color: #ed224d !important;
	border-color: #ed224d !important;
}

input[type="range"]::-webkit-slider-thumb {
	background: #ed224d !important;
}

.form-control {
	// border-top: 0 !important;
	// border-left: 0 !important;
	// border-right: 0 !important;
	// padding: 0.375rem 0rem !important;
	// border-radius: 0 !important;
}

.form-control:focus {
	outline: none !important;
	box-shadow: none !important;
	border-color: gray !important;
}

.input-group-text {
	border-top: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
	border-radius: 0 !important;
}

.form-select {
	border-radius: 0 !important;
}

.form-select:focus {
	outline: none !important;
	box-shadow: none !important;
	border-color: gray !important;
}

.heading {
	@apply tw-text-xl tw-pb-8 md:tw-pb-10 md:tw-text-4xl tw-flex tw-items-center tw-font-bold  tw-w-full tw-justify-center tw-text-center tw-text-grayDark
}
.heading-sub {
	@apply tw-text-xl tw-pb-2 md:tw-text-4xl tw-flex tw-items-center tw-font-bold  tw-w-full tw-justify-center tw-text-center tw-text-grayDark;
}

.subheading {
	@apply tw-text-center tw-pb-8 md:tw-pb-12 tw-text-base  md:tw-text-2xl tw-font-bold tw-px-4 md:tw-px-16 tw-text-pinkDark;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.scroll-container {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.scroll-container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
